import React, { useEffect, useState } from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql, navigate } from "gatsby"
import "../../graphql/fragments"
// import {
//   Accordion,
//   AccordionItem,
//   AccordionItemHeading,
//   AccordionItemButton,
//   AccordionItemPanel,
// } from "react-accessible-accordion"
import Cookies from 'universal-cookie'
import "react-accessible-accordion/dist/fancy-example.css"
import { useMediaPredicate } from "react-media-hook"
import { withFormik, Form, Field } from "formik"
import * as Yup from "yup"
import NumberFormat from "react-number-format"
import gql from "graphql-tag"
import { useMutation } from "@apollo/react-hooks"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import { CoverMain } from "../../components/cover-main/cover-main"
import { Section, Container } from "../../components/grid/grid"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const SUBMIT_FORM = gql`
  mutation Submit2PromoKaryawan2022(
    $fullName: String
    $phoneNumber: String
    $email: String
  ) {
    submit2PromoKaryawan2022(
      input: {
        clientMutationId: ""
        fullName: $fullName
        phoneNumber: $phoneNumber
        email: $email
      }
    ) {
      status
      message
    }
  }
`

const cookies = new Cookies()
// set expires (combine with date in setCookie function)
// cookies.set('cookieName', {key: value}, {path: '/', expires: new Date(Date.now()+2592000)})

const setCookie = ({cname, cvalue, exdays}) => {
  var d = new Date()
  exdays = 30
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
  var expires = "expires="+ d.toUTCString()
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/"
}

const getCookie = (cname) => {
  let name = cname + "="
  let decodedCookie = decodeURIComponent(document.cookie)
  let ca = decodedCookie.split(';')
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ""
}

const checkCookie = () => {
  let cname = getCookie("name")
  let cemail = getCookie("email")
  let cphoneNumber = getCookie("phoneNumber")
  if (cname != "" && cemail != "" && cphoneNumber != "") {
    navigate("/promo-karyawan-sinarmas-group/details")
  } else {
    console.log("data is an empty")
  }
}

const LeadForm = ({ errors, touched, values, setFieldValue, btnLoading }) => {
  const phoneNumberHandler = (val, setFieldValue) =>
    setFieldValue("phoneNumber", val)
  return (
    <Form className="row pt-4">
      <div className="col-12 col-md-4 pb-4 pb-md-0">
        <label className="text-muted my-0">Name</label>
        <Field
          name="name"
          className="form-control px-0"
          placeholder="Enter your name"
          type="text"
        />
        {touched.name && errors.name && (
          <div className="invalid-value d-block">{errors.name}</div>
        )}
      </div>
      <div className="col-12 col-md-4 pb-4 pb-md-0">
        <label className="text-muted my-0">Email</label>
        <Field
          name="email"
          className="form-control px-0"
          placeholder="Enter your email"
          type="email"
        />
        {touched.email && errors.email && (
          <div className="invalid-value d-block">{errors.email}</div>
        )}
      </div>
      <div className="col-12 col-md-4">
        <label className="text-muted my-0">Phone Number</label>
        <NumberFormat
          name="phoneNumber"
          className="form-control px-0"
          placeholder="Enter your phone number"
          allowEmptyFormatting
          prefix="+62"
          value={values.phoneNumber}
          onValueChange={val =>
            phoneNumberHandler(val.formattedValue, setFieldValue)
          }
          type="tel"
        />
        {touched.phoneNumber && errors.phoneNumber && (
          <div className="invalid-value d-block">{errors.phoneNumber}</div>
        )}
      </div>
      <div className="col-12 pt-5">
        <button disabled={btnLoading} className="btn btn-primary w-100 w-md-25">
          {btnLoading ? <i className="fa fa-spinner fa-spin"></i> : "Submit"}
        </button>
        <p className="pt-3 text-muted">          
            Sinar Mas Land will never sell or share your information with a third party
        </p>
      </div>
    </Form>
  )
}

const FaqPromoKaryawan = ({ location }) => {
  const staticData = useStaticQuery(graphql`
    query{
      imgCoverMain: file(relativePath: { eq: "imgBannerDevelpoment.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      wordPress {
        page(id: "promo-karyawan-sinarmas-group", idType: URI) {
          title
          featuredImage {
            mediaItemUrl
          }
          blocks {
            name
            ... on WordPress_CoreHeadingBlock {
              attributes {
                content
              }
            }
            ... on WordPress_CoreCoverBlock {
              attributes {
                url
                className
              }
            }
            ... on WordPress_CoreParagraphBlock {
              attributes {
                ... on WordPress_CoreParagraphBlockAttributesV3 {
                  content
                }
              }
            }
          }
          translation(language: ID) {
            title
            blocks {
              name
              ... on WordPress_CoreHeadingBlock {
                attributes {
                  content
                }
              }
              ... on WordPress_CoreCoverBlock {
                attributes {
                  url
                  className
                }
              }
              ... on WordPress_CoreParagraphBlock {
                attributes {
                  ... on WordPress_CoreParagraphBlockAttributesV3 {
                    content
                  }
                }
              }
            }
          }
          seo {
            title
            twitterTitle
            twitterDescription
            metaDesc
            opengraphTitle
            opengraphDescription
          }
        }
      }
    }
  `)

  // const [isShow, setIsShow] = useState(false)
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    phoneNumber: "",
  })
  const [submitForm, { loading: submitFormLoading }] = useMutation(SUBMIT_FORM)

  const EnhancedLeadForm = withFormik({
    mapPropsToValues({ name, phoneNumber, email }) {
      return {
        name: formValues.name || name,
        email: formValues.email || email,
        phoneNumber: formValues.phoneNumber || phoneNumber,
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      email: Yup.string().required("Email is required"),
      phoneNumber: Yup.string().required("Phone number is required"),
    }),
    handleSubmit(values) {
      setFormValues(values)
      const formatNumber = values.phoneNumber.split("+").join("")
      const timestamp = new Date().getTime()
      const expire = timestamp + (60 * 60 * 24 * 1000 * 30)
      const expireDate = new Date(expire)

      submitForm({
        variables: {
          fullName: values.name,
          phoneNumber: formatNumber,
          email: values.email,
        },
      }).then((res) => {
        console.log(res)
        console.log(values)
        if (res.data.submit2PromoKaryawan2022.status === "success") {
          cookies.set('name', values.name, { path: '/', expires: new Date(expire) })
          cookies.set('phoneNumber', formatNumber, { path: '/', expires: new Date(expire) })
          cookies.set('email', values.email, { path: '/', expires: new Date(expire) })
          alert("Terima Kasih, data Anda telah disimpan.")
          // window.location.reload()
          navigate("/promo-karyawan-sinarmas-group/details")
        }else{
          alert("Terjadi kesalahan saat menyimpan data. Silahkan mencoba kembali.")
          window.location.reload()
        }
      }).catch((e) => {
        alert("Terjadi kesalahan saat menyimpan data. Silahkan mencoba kembali.")
        window.location.reload()
      })
    },
  })(LeadForm)

  const resizeScreen = useMediaPredicate("(max-width: 992px)")
  const seo = staticData?.wordPress?.page?.seo
  const wordPress = staticData?.wordPress?.page
  let coverItems = []

  const featuredImage = staticData?.wordPress?.page?.featuredImage?.mediaItemUrl

  useEffect(() => {
    checkCookie()
  }, [])

  useEffect(() => {
    const formPromo = document.getElementsByClassName("section-from-promo")[0]
    let navbarDesktopLink = document.getElementById("desktopNav").querySelectorAll(".nav-link")

    formPromo.style.display = "block"

      if(resizeScreen){
        document.getElementsByClassName("logo-secondary")[1].style.display = `none`
        document.getElementsByClassName("logo-main")[1].style.display = `block`
      }else{
        document.getElementsByClassName("logo-secondary")[0].style.display = `none`
        document.getElementsByClassName("logo-main")[0].style.display = `block`
      }

      for (let i = 0; i < navbarDesktopLink.length; i++) {
        navbarDesktopLink[i].style.color = "#000"
      }
  }, [])

  return(
    <Layout location={location} currentLocation={location.pathname} lang="EN">
      <SEO
        title={seo?.title}
        fbTitle={seo?.opengraphTitle || seo?.title}
        twitterTitle={seo?.twitterTitle || seo?.title}
        url={location?.href}
        fbDescription={seo?.opengraphDescription}
        description={seo?.opengraphDescription}
        twitterImg={featuredImage}
        twitterDescription={seo?.twitterDescription || seo?.opengraphDescription}
        img={featuredImage}
        fbImg={featuredImage}
      />
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
        <meta name="googlebot" content="index,follow" />
        <meta name="googlebot-news" content="index,follow" />
        <meta name="title" content="Promo Khusus Karyawan Sinar Mas Group" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=yes" />
        <meta http-equiv="Content-Type" content="text/html;charset=UTF-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests" />
        <meta http-equiv="content-language" content="In-Id" />
        <meta http-equiv="cache-control" content="max-age=0" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta http-equiv="refresh" content="360" />
        <meta name="referrer" content="no-referrer-when-downgrade" />
        <meta name="theme-color" content="#ab0204" />
        <meta name="description" content="Kerja di Sinar Mas, rumahnya juga Sinar Mas dong! Yuk manfaatkan benefit khusus Karyawan Sinar Mas. Berlaku hanya sampai 30 JUNI 2022" />
        <meta name="keywords" content="promo kpr karyawan, promo kavling karyawan, karyawan sinar mas group, free dp, khusus karyawan sinar mas group, cicilan sampai 70%, tenor 20 tahun, sinarmasland" />
        <meta name="language" content="id"  />
        <meta name="geo.country" content="id" />
        <meta name="geo.placename" content="Indonesia" />
        <meta name="author" content="sinarmasland developer" />
        <meta name="copyright" content="sinarmasland.com, All Rights Reserved" />
        <link rel="shortcut icon" type="image/x-icon" href="https://www.sinarmasland.com/favicon-32x32.png?v=272d2e7778b1f803fb7ed2ea59ec8d05" />
        <link rel="apple-touch-icon-precomposed" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="canonical" href="https://www.sinarmasland.com/promo-karyawan-sinarmas-group" />
        <meta property="fb:app_id" content="401011690922768" />
        <meta property="og:title" content="Promo Khusus Karyawan Sinar Mas Group" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.sinarmasland.com/promo-karyawan-sinarmas-group" />
        <meta property="og:image" content="https://www.sinarmasland.com/app/uploads/2022/05/showcase.jpeg" />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="1024" />
        <meta property="og:image:height" content="768" />
        <meta property="og:updated_time" content="1653628659" />
        <meta property="og:description" content="Kerja di Sinar Mas, rumahnya juga Sinar Mas dong! Yuk manfaatkan benefit khusus Karyawan Sinar Mas. Berlaku hanya sampai 30 JUNI 2022." />
        <meta property="og:site_name" content="sinarmasland.com" />
        <meta property="article:author" content="https://www.sinarmasland.com" />
        <meta property="article:publisher" content="https://www.sinarmasland.com" />
        <meta property="twitter:site" content="https://twitter.com/sinarmas_land" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:creator" content="@sinarmas_land" />
        <meta property="twitter:title" content="Promo Khusus Karyawan Sinar Mas Group." />
        <meta property="twitter:description" content="Kerja di Sinar Mas, rumahnya juga Sinar Mas dong! Yuk manfaatkan benefit khusus Karyawan Sinar Mas. Berlaku hanya sampai 30 JUNI 2022." itemprop="description" />
        <meta property="twitter:image" content="https://www.sinarmasland.com/app/uploads/2022/05/showcase.jpeg" />
        <body className="bd-page" />
      </Helmet>
      {/* <CoverMain
        h1={""}
        img={
          resizeScreen 
            ? coverItems[1]?.url
            : coverItems[0]?.url
          }
        alt={wordPress?.title}
        className={`cover-md ${resizeScreen ? coverItems[1]?.className : coverItems[0]?.className}`}
      /> */}
      <Section className="section-from-promo">
        <Container>
          {/* <h2>Get More Information About Sinarmas Employees Promo</h2>
          <p className="text-muted">Find out more about this project</p>
          <EnhancedLeadForm btnLoading={submitFormLoading} /> */}
          <h2>This Event Has Ended</h2>
          <p className="text-muted">Please click here to go <a href="/">Homepage</a></p>
        </Container>
      </Section>
      {/* <Section className="promo-content">
        {wordPress?.faqContentPromo?.faqContentPromo && (
          <div className="container" dangerouslySetInnerHTML={{__html: wordPress?.faqContentPromo?.faqContentPromo }}></div>
        )}
      </Section> */}
      {/* {wordPress?.blocks.map((item, i) => {
        switch (item.name) {
          case "core/heading":
            return (
              <div key={`heading-${i}`} className="container container-md">
                <div className={i === 0 ? "py-main pb-3 pt-4" : "pt-main"}>
                  <HeadingBasic h2={item.attributes.content} />
                </div>
              </div>
            )
          case "core/paragraph":
            return (
              <div
                key={`headingbasic-${i}`}
                className={`py-main pb-3 pt-3`}
              >
                <div className="container container-md">
                  <HeadingBasic text={[item.attributes.content]} />
                </div>
              </div>
            )
          default:
            return <></>
        }
      })} */}
      {/* <Section className="main-faq-promo-karyawan">
        <Container>
          <Accordion preExpanded={[1]} allowZeroExpanded>
            <AccordionItem uuid={1}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  FAQ Promo Karyawan Sinarmas Group
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Accordion allowZeroExpanded>
                  <AccordionItem id="aci1">
                    <AccordionItemHeading id="ach1">
                      <AccordionItemButton>
                        1. Apakah yang dimaksud dengan Program KPR Khusus Karyawan Sinar Mas Group?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Program ini adalah program kepemilikan properti untuk karyawan Sinar Mas Group yang diadakan oleh Sinar Mas Land bekerjasama dengan bank partner, untuk memberikan kemudahan dan keringanan bagi karyawan Sinar Mas Group yang membutuhkan properti melalui program KPR.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem id="aci2">
                    <AccordionItemHeading id="ach2">
                      <AccordionItemButton>
                        2. Apa saja perusahaan Sinar Mas Group yang termasuk dalam program ini?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Semua anak perusahaan Sinar Mas Group, termasuk perusahaan joint venture dapat berpartisipasi dalam program ini.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem id="aci3">
                    <AccordionItemHeading id="ach3">
                      <AccordionItemButton>
                        3. Siapa yang bisa mengajukan program KPR Khusus Karyawan Sinar Mas ini?  
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Semua karyawan Sinar Mas Group berhak untuk mengajukan program KPR Khusus Karyawan Sinar Mas ini 
                        <i>*) Syarat & ketentuan berlaku</i>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem id="aci4">
                    <AccordionItemHeading id="ach4">
                      <AccordionItemButton>
                        4. Apakah ada persyaratan masa kerja dan ketentuan ikatan kerja untuk mengikuti program ini?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Ada persyaratan masa kerja dan ketentuan ikatan kerja untuk mengikuti program ini, sesuai syarat dan ketentuan yang ditetapkan oleh bank yang bekerjasama.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem id="aci5">
                    <AccordionItemHeading id="ach5">
                      <AccordionItemButton>
                        5. Kapan periode promo KPR Khusus Karyawan Sinar Mas? 
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Promo berlaku sampai dengan 30 Juni 2022*.
                        <i>*) Syarat & ketentuan berlaku</i>
                      </p>                      
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem id="aci6">
                    <AccordionItemHeading id="ach6">
                      <AccordionItemButton>
                        6. Apa saja keringanan yang ditawarkan dalam program ini?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <ul>
                        <li>
                          Free DP*
                        </li>
                        <li>
                          BUNGA KPR mulai dari 3,65%*
                        </li>
                        <li>
                          TENOR s.d. 20 tahun*
                        </li>
                        <li>
                          CICILAN s.d. 70% dari income*
                        </li>
                        <li>
                          FREE Biaya Admin & Provisi*
                        </li>
                        <li>
                          Bisa Bayar BUNGANYA AJA dulu!*
                        </li>
                      </ul>
                      <p>
                        <i>*) Syarat & ketentuan berlaku</i>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem id="aci7">
                    <AccordionItemHeading id="ach7">
                      <AccordionItemButton>
                        7. Proyek apa saja yang ditawarkan dalam Program KPR Sinar Mas ini? Dan di mana lokasinya?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <ul>
                        <li>
                          Program ini berlaku untuk semua proyek properti Sinar Mas Land, kecuali proyek-proyek JV (Joint Venture)*
                        </li>
                        <li>
                          Lokasi proyek: BSD City, Tangerang, Jakarta, Cibubur, Surabaya, Batam, Balikpapan
                        </li>
                        <li>
                          Proyek Joint Venture (TIDAK termasuk dalam program) : Nava Park, The Zora, Upper West, Akasa, Grand Wisata, Kota Deltamas
                        </li>
                      </ul>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem id="aci8">
                    <AccordionItemHeading id="ach8">
                      <AccordionItemButton>
                        8. Jenis produk apa saja yang ditawarkan dalam program KPR Sinar Mas?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Program ini berlaku untuk pembelian rumah, apartemen, ruko (Non kavling) sesuai kebutuhan dari karyawan.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem id="aci9">
                    <AccordionItemHeading id="ach9">
                      <AccordionItemButton>
                        9. Bagaimana cara mendapatkan keringanan untuk program KPR Sinar Mas?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>Silakan menghubungi (021) 5315 9000, atau dapat langsung menghubungi contact number di bawah ini untuk informasi lebih lanjut.</p><br/><br/>
                      <p>Informasi Proyek</p>
                      <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Proyek</th>
                            <th>Contact Number</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="font-weight-bold">
                              <a href="#">Residential BSD</a>
                            </td>
                            <td>
                              <a href="tel:085712359000">0857 1235 9000</a>
                            </td>
                          </tr>
                          <tr>
                            <td className="font-weight-bold">
                              <a href="#">Residential Non-BSD</a>
                            </td>
                            <td>
                              <a href="tel:08128315050">0812 831 5050</a> <span>(kota wisata)</span>
                            </td>
                          </tr>
                          <tr>
                            <td className="font-weight-bold">
                              <a href="#">Commercial</a>
                              </td>
                            <td>
                              <a href="tel:08159137981">0815 9137 981</a> <span>(Ibu Andriani Otang)</span>
                            </td>
                          </tr>
                          <tr>
                            <td className="font-weight-bold">
                              <a href="#">Apartemen Jakarta</a>
                            </td>
                            <td>
                              <span>Southgate: </span><a href="tel:081280488585">0812 8048 8585</a><span> (Ibu Etik)</span><br />
                              <span>The Elements: </span><a href="tel:085655232288">0856 5523 2288</a><span> (Ibu Mayo)</span><br />
                              <span>Aerium: </span><a href="tel:08128299903">0812 8299 903</a><span> (Bapak Hendra)</span>
                            </td>
                          </tr>
                          <tr>
                            <td className="font-weight-bold">
                              <a href="#">Surabaya</a>
                            </td>
                            <td>
                              <a href="tel:081389990780">0813 8999 0780</a> <span>(Hot Line)</span>
                            </td>
                          </tr>
                          <tr>
                            <td className="font-weight-bold">
                              <a href="#">Balikpapan</a>
                            </td>
                            <td>
                              <a href="tel:082157891961">0821 5789 1961</a> <span>(Budi Widiyanto)</span>
                            </td>
                          </tr>
                          <tr>
                            <td className="font-weight-bold">
                              <a href="#">Batam</a>
                            </td>
                            <td>
                              <a href="tel:08117008238">0811 7008 238</a> <span>(CS Nuvasa Bay)</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      </div>
                      <br/>
                      <br/>
                      <p>Informasi Bank</p>
                      <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Bank Partner</th>
                            <th>Contact Number</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="font-weight-bold">
                              <a href="#">Panin Bank</a>
                            </td>
                            <td>
                              <a href="tel:087808777037">0878 0877 7037</a> <span>(Bapak Pandu)</span>
                            </td>
                          </tr>
                          <tr>
                            <td className="font-weight-bold">
                              <a href="#">CIMB Niaga</a>
                            </td>
                            <td>
                              <a href="tel:08119862722">0811 9862 722</a> <span>(Ibu Kirana)</span>
                            </td>
                          </tr>
                          <tr>
                            <td className="font-weight-bold">
                              <a href="#">Permata Bank</a>
                            </td>
                            <td>
                              <a href="tel:083821881441">0838 2188 1441</a> <span>(Bapak Kelvin)</span><br />
                              <a href="tel:089647634099">0896 4763 4099</a> <span>(Ibu Jessica)</span>
                            </td>
                          </tr>
                          <tr>
                            <td className="font-weight-bold">
                              <a href="#">BCA</a>
                            </td>
                            <td>
                              <a href="tel:08112331188">0811 2331 188</a> <span>(Ibu Novi Bernadette)</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem id="aci10">
                    <AccordionItemHeading id="ach10">
                      <AccordionItemButton>
                        10. Siapa saja Bank yang sudah bekerjasama dalam program ini?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Permata Bank, CIMB Niaga, Panin Bank, dan BCA.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem id="aci11">
                    <AccordionItemHeading id="ach11">
                      <AccordionItemButton>
                        11. Apakah program yang diberikan berlaku sama untuk semua bank partner?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Masing-masing bank memiliki benefit khusus untuk karyawan Sinar Mas Group dengan syarat dan kondisi yang berbeda.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem id="aci12">
                    <AccordionItemHeading id="ach12">
                      <AccordionItemButton>
                        12. Apakah promo produk yang sudah ada sebelumnya tetap berlaku pada program ini?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Promo produk tetap mengacu pada skema promo yang sudah berlaku sesuai syarat dan ketentuan yang sudah ditetapkan. Jadi program ini hanya untuk memberikan kemudahan bagi yang memilih pembiayaan KPR.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </Container>
      </Section> */}
    </Layout>
  )
}
export default FaqPromoKaryawan